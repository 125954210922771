import { Fragment } from "react"
import styled from "styled-components"

import { TMButtonProps } from "src/ui/Button/MButton"
import { divider } from "src/ui/colors"
import { spacing } from "src/ui/spacing"
import { VerticalWizardButtonRow } from "src/ui/VerticalWizard/VerticalWizardButtonRow"

import { Step } from "./Step"

export function VerticalWizard({
  steps,
  currentStep,
  onNext,
  onEdit,
}: {
  steps: IVerticalWizardStep[]
  currentStep: number
  onNext: () => void
  onEdit: (stepNumber: number) => void
}) {
  const filteredSteps = steps.filter((step) => !step.skip)

  return (
    <StepGrid>
      {filteredSteps.map((step, i) => {
        const {
          title,
          component,
          preview,
          completed,
          onNext: onNextOverride,
          onEdit: onEditOvverride,
          nextButtonLabel,
          nextButtonProps,
        } = step

        const isCurrentStep = currentStep === i
        const showCheckmark = completed && !isCurrentStep
        const isEditable = i < currentStep || (completed && !isCurrentStep)

        return (
          <Fragment key={i}>
            <Step
              title={title}
              stepIndex={i}
              showCheckmark={showCheckmark}
              preview={preview}
              hidePreview={isCurrentStep || !completed}
              editable={isEditable}
              onEdit={onEditOvverride || onEdit}
            >
              {isCurrentStep && (
                <>
                  <ComponentBox>{component}</ComponentBox>
                  <VerticalWizardButtonRow
                    currentStep={currentStep}
                    onNext={onNextOverride || onNext}
                    nextButtonProps={nextButtonProps}
                    nextButtonLabel={nextButtonLabel}
                  />
                </>
              )}
            </Step>
          </Fragment>
        )
      })}
    </StepGrid>
  )
}

const StepGrid = styled.div`
  > * {
    padding-bottom: ${spacing.L};
    margin-bottom: ${spacing.L};
    border-bottom: 1px solid ${divider};
  }

  > *:last-child {
    border: none;
  }
`

const ComponentBox = styled.div`
  margin-top: ${spacing.M};
`

export interface IVerticalWizardStep {
  id?: string
  title: React.ReactNode
  component: React.ReactNode
  completed: boolean
  onNext?: () => void
  onEdit?: () => void
  preview?: React.ReactNode
  nextButtonLabel?: string
  nextButtonProps?: TMButtonProps
  skip?: boolean
}
