import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MButton, TMButtonProps } from "src/ui/Button/MButton"
import { spacing } from "src/ui/spacing"

export function VerticalWizardButtonRow({
  onNext,
  currentStep,
  nextButtonProps,
  nextButtonLabel,
}: {
  onNext?: () => void
  currentStep: number
  nextButtonProps?: TMButtonProps
  nextButtonLabel?: string
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <MButton
        // This is needed to force a rerender, otherwise if you have a step
        // with a form on the next step, it will fire a form event essentially
        // skipping a step
        key={currentStep}
        type={!!nextButtonProps?.form ? "submit" : "button"}
        onClick={!nextButtonProps?.form ? onNext : undefined}
        {...nextButtonProps}
      >
        {nextButtonLabel || t(langKeys.next)}
      </MButton>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: ${spacing.M} 0 0;
`
